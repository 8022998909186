import { combineReducers } from 'redux'

import { appReducer as app, AppState } from 'features/app/app.reducer'
import { authReducer as auth, AuthState } from 'features/auth/auth.reducer'
import { userReducer as user, UserState } from 'features/user/user.reducer'

export interface IState {
  app: AppState
  auth: AuthState
  user: UserState
}

export const reducer = combineReducers({
  app,
  auth,
  user
})
