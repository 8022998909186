import React, { FC } from "react"
import { useLocation } from "react-router-dom"
import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"

type PropsType = {
  children: React.ReactNode
}

const Layout: FC<PropsType> = ({ children }) => {
  const location = useLocation()
  const isLoginPage = location.pathname === "/login"
  const isRegistrationPage = location.pathname === "/registration"
  const isRouteEventsPage = location.pathname.includes('route-events')
  const isPostsPage = location.pathname.includes('posts')

  if (isLoginPage || isRegistrationPage || isRouteEventsPage || isPostsPage) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout
