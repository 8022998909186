import {setShowEmailConfirmModal} from '../../features/app/app.actions'
import Button from '../Button/Button'
import {Modal} from 'antd'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks'
import {getAppShowEmailConfirmModal} from '../../features/app/app.selectors'
import {UserServices} from '../../services/UserServices'
import {ProfileService} from '../../services/ProfileService'
import {setUser} from '../../features/user/user.actions'

const EmailConfirmModal = () => {
  const showEmailConfirmModal = useAppSelector(getAppShowEmailConfirmModal)
  const dispatch = useAppDispatch()
  const [currentView, setCurrentView] = useState<'confirm' | 'code'>('confirm')
  const [confirmCode, setConfirmCode] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (currentView === 'code' && inputRef?.current) {
      inputRef.current.focus()
    }
  }, [currentView, inputRef])
  const sendEmail = async () => {
    const resp = await UserServices.sendCodeToEmail()
    console.log(resp.data)
    if (resp.data) {
      setCurrentView('code')
    }
  }

  const sendConfirmCode = async () => {
    if (confirmCode) {
      const resp = await UserServices.confirmEmail({ code: confirmCode })
      console.log(resp.data)
      if (resp.data) {
        const userResp = await ProfileService.getCurrent()
        if (userResp.data) {
          dispatch(setUser(userResp.data))
        }
        closeModalHandler()
      }
    }
  }

  const closeModalHandler = () => {
    dispatch(setShowEmailConfirmModal(false))
  }

  return (
    <Modal open={showEmailConfirmModal} onCancel={closeModalHandler}>
      <div className="confirm-email-modal">
        {currentView === 'confirm' && (
          <div className="confirm-email-modal__wrap">
            <div className="confirm-email-modal__title">Подтверждение email</div>
            <div className="confirm-email-modal__text">Вам необходимо подтвердить ваш email</div>
            <div className="confirm-email-modal__footer">
              <Button className="confirm-email-modal__submit" onClick={sendEmail}>Подтвердить</Button>
              <Button className="confirm-email-modal__cancel" onClick={closeModalHandler}>Отмена</Button>
            </div>
          </div>
        )}
        {currentView === 'code' && (
          <div className="confirm-email-modal__wrap">
            <div className="confirm-email-modal__title">Подтверждение email</div>
            <div className="confirm-email-modal__text">Код отправлен на ваш email. Введите код</div>
            <div className="confirm-email-modal__input">
              <input
                type="text"
                value={confirmCode}
                onChange={(evt) => setConfirmCode(evt.target.value)}
                ref={inputRef}
              />
            </div>
            <div className="confirm-email-modal__footer">
              <Button className="confirm-email-modal__submit" onClick={sendConfirmCode}>Отправить код</Button>
              <Button className="confirm-email-modal__cancel" onClick={closeModalHandler}>Отмена</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default EmailConfirmModal
