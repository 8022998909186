import {FC, ReactNode} from 'react'

type PropsType = {
  isGhost?: boolean
  children: ReactNode
  className?: string
  width?: string
  isSubmit?: boolean
  onClick: (evt: any) => void
  isDisabled?: boolean
}

const Button: FC<PropsType> = ({ children, isGhost = false, className, width, onClick, isSubmit, isDisabled = false }) => {
  return (
    <button
      className={`btn${isGhost ? " btn--ghost" : ""}${className ? " " + className : ""}`}
      style={width ? { width } : undefined}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      disabled={isDisabled}
    >
      {children}
    </button>
  )
}

export default Button
