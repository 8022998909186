import { AnyAction } from 'redux'
import * as actionType from './auth.types'
import {apiService} from '../../index'

export interface AuthState {
  isLoggedIn: boolean
  isLoading: boolean
  errorMsg: string
  token: string | null
}

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  errorMsg: '',
  token: localStorage.getItem('t13-token') || null
}

export const authReducer = (state = initialState, { type, payload }: AnyAction): AuthState => {
  switch (type) {
    case actionType.SET_IS_LOGGED_IN: {
      return { ...state, isLoggedIn: payload }
    }
    case actionType.SET_TOKEN: {
      if (payload) {
        localStorage.setItem('t13-token', payload)
        apiService.setToken(payload)
        return { ...state, token: payload }
      }
      return state
    }
    case actionType.AUTH_LOGOUT: {
      localStorage.clear()
      apiService.setToken(null)
      return { ...state, isLoggedIn: false }
    }
    default:
      return state
  }
}
