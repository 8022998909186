import React from "react"
import logoT13 from "components/Res/logoT13.svg"
import VkIcon from "components/Res/iconVK.svg"
import TelegramIcon from "components/Res/iconTelegram.svg"
import YoutubeIcon from "components/Res/iconYoutube.svg"
import { Link } from "react-router-dom"
import footerLogo from "components/Res/logoT13_big.svg"
import { FileOutlined } from "@ant-design/icons"

const Footer = () => (
  <footer className="footer">
    <div className="footer__wrap container">
      <div className="footer__right">
        <Link className="footer__logo" to="/">
          <img src={logoT13} alt="Team 13 logo" />
        </Link>
        <div className="footer__row">
          <a href="https://vk.com/team13_russia" target="_blank" rel="noreferrer">
            <img src={VkIcon} alt="vk icon" />
          </a>
          <a href="https://t.me/Team13_Russia" target="_blank" rel="noreferrer">
            <img src={TelegramIcon} alt="telegram icon" />
          </a>
          <a href="https://www.youtube.com/@Team13_Russia" target="_blank" rel="noreferrer">
            <img src={YoutubeIcon} alt="youtube icon" />
          </a>
          <a href="mailto:info@т13.рф">info@т13.рф</a>
        </div>
      </div>
      {window.location.pathname == "/" && (
        <div className="footer__right">
          <div className="footer__col">
            <span>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГЛАССЕН-АЙТИ</span>
            <p>ИНН 7838317591"</p>
          </div>
          <a href="/oferta.pdf" target={"_blank"}>
            <p>Оферта</p>
          </a>
        </div>
      )}
      <img src={footerLogo} className="footer__bg" alt="T13" />
    </div>
  </footer>
)

export default Footer
