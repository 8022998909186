import React from 'react'

const Spinner = () => {
  return (
    <div className="app-spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default Spinner
