import React, {useContext, useState} from 'react'
import { NavLink } from "react-router-dom"
import {getAuthIsLoggedInSelector} from '../../../features/auth/auth.selectors'
import {useAppSelector} from '../../../hooks'
import {UserServices} from '../../../services/UserServices'

type NavItemType = {
  path: string
  text: string
  showOnAuth?: boolean
  authOnly?: boolean
  isDisabled?: boolean
  showFriendRequestsCount?: boolean
}

const navList: NavItemType[] = [
  { path: "/about", text: "О нас" },
  { path: "/videos", text: "Видео" },
  { path: "/events", text: "События" },
  { path: "/travels", text: "Путешествия" },
  { path: "/team", text: "Команда", authOnly: true, showFriendRequestsCount: true },
  { path: "/dtp", text: "Обсуждения" },
]

const Navigation = ({updateData}:any) => {
  const isLoggedIn = useAppSelector(getAuthIsLoggedInSelector)
  const [friendRequestsCount, setFriendRequestsCount] = useState(0)

  const getFriendRequests = () => {
    UserServices.getFriendRequests().then((resp) => {
      setFriendRequestsCount(resp.data.count)
    })
  }

  const renderItem = (item: NavItemType) => {
    if (!isLoggedIn && item.authOnly) {
      return (
        <li
          key={item.text}
          className="header-nav__item header-nav__item--disabled"
          onClick={()=>updateData(true)}
        >
          <span>{item.text}</span>
          <div className="header-nav__tooltip">Доступно зарегистрированным пользователям</div>
        </li>
      )
    }

    return (
      <li
        key={item.text}
        className="header-nav__item"
        onClick={()=>updateData(true)}
      >
        <NavLink to={item.path} className={({ isActive }) => (isActive ? "active" : undefined)}>
          {item.text}
          {item.showFriendRequestsCount && friendRequestsCount > 0 ? <div className="header-nav__count">{friendRequestsCount}</div> : null}
        </NavLink>
      </li>
    )
  }

  return (
    <nav className="header-nav">
      <ul>{navList.map(renderItem)}</ul>
    </nav>
  )
}

export default Navigation
