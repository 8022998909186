import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux'
import {store} from 'store'
import { BrowserRouter } from "react-router-dom"
import "./styles/index.scss"
import Team13App from "./App"
import {ApiService} from './services/ApiService'

export const apiService = new ApiService()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Team13App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
)
