import { AnyAction } from 'redux'
import * as actionType from './user.types'

export interface UserState {
  id: number | null
  username: string | null
  phone: number | null
  email: number | null
  email_confirmed: boolean
  user_profile: {
    active: boolean | null
    address: string | null
    avatar: string | null
    map_avatar: string | null
    web_avatar: string | null
    banner: string | null
    date_birthday: string | null
    home_latitude: number | null
    home_longitude: number | null
    rang: number | null
    first_name: string | null
    last_name: string | null
    callsign: string | null
    telegram_nickname: string | null
  }
}

const localUser = localStorage.getItem('user')

const initialState: UserState = localUser ? JSON.parse(localUser) : {
  id: null,
  username: null,
  phone: null,
  email: null,
  user_profile: {
    active: null,
    address: null,
    avatar: null,
    banner: null,
    date_birthday: null,
    home_latitude: null,
    home_longitude: null,
    rang: null,
    first_name: null,
    last_name: null,
    callsign: null
  }
}

export const userReducer = (state = initialState, { type, payload }: AnyAction): UserState => {
  switch (type) {
    case actionType.SET_USER: {
      localStorage.setItem('user', JSON.stringify(payload.user))
      return { ...state, ...payload.user }
    }
    case actionType.RESET_USER: {
      localStorage.removeItem('user')
      return { ...state, ...initialState }
    }
    default:
      return state
  }
}
