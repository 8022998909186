import {apiService} from '../index'

export class UserServices {
  static async getImages (id: string) {
    return await apiService.baseApiRequest('get', `/user/${id}/get_images/`)
  }
  static async getUsers (params: any) {
    let queries = ''
    Object.keys(params).forEach((query, idx) => {
      // @ts-ignore
      if (params[query]) {
        // @ts-ignore
        queries = queries + `${queries === '' ? '?' : '&'}${query}=${params[query]}`
      }
    })
    return await apiService.baseApiRequest('get', `/user/${queries}`)
  }
  static async getById (id: string) {
    return await apiService.baseApiRequest('get', `/user/${id}/`)
  }
  static async getVideos (id: string) {
    return await apiService.baseApiRequest('get', `/user/${id}/get_videos/`)
  }
  static async getEquipments (id: string) {
    return await apiService.baseApiRequest('get', `/user/${id}/get_equipments/`)
  }
  static async getAllEquipments () {
    return await apiService.baseApiRequest('get', `/equipment/`)
  }
  static async getFriends () {
    return await apiService.baseApiRequest('get', `/user/get_friends/`)
  }
  static async getFriendRequests () {
    return await apiService.baseApiRequest('get', `/friend_requests/`)
  }
  static async acceptRequest (id: string) {
    return await apiService.baseApiRequest('post', `/friend_requests/${id}/accept_request/`)
  }
  static async declineRequest (id: string) {
    return await apiService.baseApiRequest('post', `/friend_requests/${id}/decline_request/`)
  }
  static async removeFromFriends (id: string) {
    return await apiService.baseApiRequest('delete', `/user/${id}/remove_from_friends/`)
  }
  static async addToFriends (id: string) {
    return await apiService.baseApiRequest('post', `/user/${id}/add_to_friends/`)
  }
  static async changePassword (params: any) {
    return await apiService.baseApiRequest('post', `/user/change_password/`, params)
  }
  static async sendCodeToEmail () {
    return await apiService.baseApiRequest('post', `/user/send_code_email/`, {})
  }
  static async confirmEmail (params: any) {
    return await apiService.baseApiRequest('post', `/user/confirm_email/`, params)
  }
}
