import { AnyAction } from 'redux'
import * as actionType from './auth.types'
import {apiService} from '../../index'
import {ProfileService} from '../../services/ProfileService'
import {setUser} from '../user/user.actions'
import {AppDispatch} from '../../store'
import {CityService} from '../../services/CityService'
import {CityType} from '../../models/CityType'
import {setUserCity, setShowEmailConfirmModal, setCurrentCity, resetCurrentCity} from '../app/app.actions'
import LogRocket from 'logrocket'

export const setIsLoggedIn = (value: boolean): AnyAction => ({ type: actionType.SET_IS_LOGGED_IN, payload: value })
export const authLogout = (): AnyAction => ({ type: actionType.AUTH_LOGOUT })

export const setToken = (value: string | null) => async (dispatch: AppDispatch) => {
  apiService.setToken(value)
  try {
    const [profile, cities] = await Promise.all([
      ProfileService.getCurrent(),
      CityService.getCities()
    ])
    if (profile.error) {
      dispatch(authLogout())
    }

    if (profile.data && cities.data) {
      LogRocket.init('team13/team13');
      LogRocket.identify(profile.data.username, {
        email: profile.data.email
      })
      dispatch(setUser(profile.data))
      // if (!profile.data.email_confirmed) {
      //   dispatch(setShowEmailConfirmModal(true))
      // }
      const currentCity = cities.data.find((it: CityType) => it.city === profile.data.user_profile.address)
      if (currentCity) {
        dispatch(setUserCity(currentCity))
      }
      const localStorageFilter = localStorage.getItem('main-filter')
      const currentFilter = localStorageFilter ? JSON.parse(localStorageFilter) : undefined
      if (currentFilter?.city !== 'Все города' && currentFilter?.selectedCity) {
        dispatch(setCurrentCity(currentFilter.selectedCity))
      }
      if (currentFilter?.city !== 'Все города') {
        dispatch(resetCurrentCity())
      }
      dispatch(setIsLoggedIn(true))
      dispatch({ type: actionType.SET_TOKEN, payload: value })
    }
  } catch (e) {
    dispatch(authLogout())
  }
}
