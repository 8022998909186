import { AnyAction } from 'redux'
import * as actionType from './app.types'
import {CityType} from '../../models/CityType'

export interface AppState {
  userCity: CityType
  currentCity: CityType
  scrollLock: boolean
  showEmailConfirmModal: boolean
}

const defaultCity = {
  address: "г Санкт-Петербург",
  city: "Санкт-Петербург",
  geo_lat: 59.9391313,
  geo_lon: 30.3159004,
  id: 787,
  postal_code: "190000"
}

const localStorageCity = localStorage.getItem('userCity')
const defaultInitialCity = localStorageCity ? JSON.parse(localStorageCity) : defaultCity

const initialState: AppState = {
  userCity: defaultInitialCity,
  currentCity: defaultInitialCity,
  scrollLock: false,
  showEmailConfirmModal: false
}

export const appReducer = (state = initialState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case actionType.SET_USER_CITY: {
      localStorage.setItem('userCity', JSON.stringify(payload))
      return { ...state, userCity: payload, currentCity: payload }
    }
    case actionType.SET_CURRENT_CITY: {
      return { ...state, currentCity: payload }
    }
    case actionType.RESET_CURRENT_CITY: {
      return { ...state, currentCity: initialState.userCity }
    }
    case actionType.SET_SCROLL_LOCK: {
      return { ...state, scrollLock: payload }
    }
    case actionType.SET_SHOW_EMAIL_CONFIRM_MODAL: {
      return { ...state, showEmailConfirmModal: payload }
    }
    default:
      return state
  }
}
