import {apiService} from '../index'

export class ProfileService {
  static async getCurrent () {
    return await apiService.baseApiRequest('get', '/profile/current/')
  }

  static async updateCurrent (id: string, params: any) {
    return await apiService.baseApiRequest('patch', `/profile/${id}/`, params)
  }

  static async get (id: string) {
    return await apiService.baseApiRequest('get', `/profile/${id}/`)
  }

  static async changeAvatar (params: any) {
    return await apiService.baseApiRequest('patch', `/profile/change_avatar/`, params, 'multipart/form-data')
  }

  static async changeMapAvatar (params: any) {
    return await apiService.baseApiRequest('patch', `/profile/change_map_avatar/`, params, 'multipart/form-data')
  }
}
