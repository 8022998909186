import React, {useEffect} from 'react'
import { YMaps } from 'react-yandex-maps'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU';
import "./App.css"
import Layout from "./layout"
import AppRoutes from "./routes"
import {getAuthTokenSelector} from './features/auth/auth.selectors'
import {setToken} from './features/auth/auth.actions'
import {useAppDispatch, useAppSelector} from './hooks'
import {getAppScrollLockSelector} from './features/app/app.selectors'

const Team13App = () => {
  const token = useAppSelector(getAuthTokenSelector)
  const scrollLock = useAppSelector(getAppScrollLockSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (token) {
      dispatch(setToken(token))
    }
  }, [token])

  useEffect(() => {
    const body = document.querySelector('body')
    if (scrollLock) {
      body?.classList.add('scroll-lock')
    } else {
      body?.classList.remove('scroll-lock')
    }
  }, [scrollLock])

  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          colorBgBase: "#201E1E",
          colorText: "#FFFFFF",
        },
      }}
    >
      <YMaps
        query={{ apikey: '39b3fad2-ca0e-40c2-a870-9663276e5d61' }}
        version={'2.1.79'}
      >
        <Layout>
          <AppRoutes />
        </Layout>
      </YMaps>
    </ConfigProvider>
  )
}

export default Team13App
